var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "homeCarousel", attrs: { id: "newCarousel" } },
    [
      _c(
        "a-carousel",
        { attrs: { autoplay: "" } },
        [
          _vm._l(_vm.lists, function(item) {
            return [
              _c("a", { key: item.id }, [
                _c("div", [
                  _c("div", { staticClass: "homeCarousel-bg" }, [
                    _c("div", {
                      staticClass: "bg",
                      style: {
                        backgroundImage: "url(" + item.thumb + ")",
                        backgroundSize: "cover"
                      }
                    }),
                    _c("div", { staticClass: "position" })
                  ]),
                  _c("p", { staticClass: "homeCarousel-title" }, [
                    _vm._v(_vm._s(item.title))
                  ])
                ])
              ])
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }