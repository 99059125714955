<template>
  <div class="homeCarousel" id="newCarousel">
    <a-carousel autoplay>
      <template v-for="item in lists">
        <a :key="item.id">
          <div>
            <div class="homeCarousel-bg">
              <div
                class="bg"
                :style="{ backgroundImage: 'url(' + item.thumb + ')', backgroundSize: 'cover' }"
              ></div>
              <div class="position"></div>
            </div>
            <p class="homeCarousel-title">{{ item.title }}</p>
          </div>
        </a>
      </template>
    </a-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
      id: '',
    };
  },
  watch: {
    $route: function(to, from) {
      // 拿到目标参数 to.query.id 去再次请求数据接口
      this.id = to.params.id;
      this.newCarousel();
    },
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.newCarousel();
  },
  methods: {
    newCarousel() {
      return this.$request(Apis.forum.NEWIMAGE, { data: { cate_id: this.id } }).then(data => {
        this.lists = data.data.data;
      });
    },
  },
};
</script>
<style lang="less">
#newCarousel .slick-dots {
  bottom: 70px;
  li {
    margin: 0 10px;
    button {
      width: 15px !important;
      height: 15px;
      border-radius: 50% !important;
    }
  }
}
</style>
<style lang="less" scoped>
.homeCarousel {
  .ant-carousel {
    height: 100%;

    .slick-initialized {
      height: 100%;
      .slick-list {
        height: 100%;
      }
      .slick-slide {
        height: 100%;
        .homeCarousel-bg {
          background: #eee;
          // height: 330px;
          background-size: cover;
          position: relative;
          .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
          .position {
            padding-bottom: 100%;
          }
        }
        .homeCarousel-title {
          text-align: center;
          margin: 0;
          background: #fff;
          line-height: 35px;
          font-size: 14px;
          font-weight: 600;
          color: #000;
          position: relative;
        }
      }
    }
  }
}
</style>
